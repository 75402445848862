<template>
	<div class="container">
		<w-reset-pwd navigateToAfterPasswordReset="/prihlasit-sa"/>
	</div>
</template>

<script>
export default {
	components: {
		'w-reset-pwd': () => import('./w-reset-pwd.vue')
	},
	
	created() {
		if (this.$store.getters['wAuth/isLoggedIn']) this.$router.push('/profil')
	}
}
</script>
